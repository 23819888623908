import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "new-card" }
const _hoisted_2 = {
  key: 0,
  class: "card-header"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-lg-6 col-md-6 col-sm-12" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "col-12 col-md-6 col-sm-12 d-lg-flex d-md-flex d-sm-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.withHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "title")
              ]),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "subtitle")
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _renderSlot(_ctx.$slots, "action")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["content", { 'pt-0 pb-0': !_ctx.withHeader }])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}