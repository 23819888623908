
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    items: {
      default: () => {
        return [] as any;
      },
    },
  },
});
