
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';
import timeUtil from '@/utils/time';
import { acronym } from '@/utils/text';

export default defineComponent({
  props: {
    info: { default: null } as any,
  },
  components: {
    Avatar,
  },
  computed: {
    lastLoggedInDate() {
      return this.info?.meta
        ? timeUtil.dateFormat(this.info?.meta?.last_login, 'aus')
        : 'Not Logged In';
    },
    userId() {
      return this.info?.user?.id;
    },
    tenantAcronym() {
      const firstName = (this.info as any)?.first_name;
      const lastName = (this.info as any)?.last_name;

      if (firstName === '' && lastName === '')
        return acronym((this.info as any)?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    tenantAvatarSrc() {
      if (!this.info.avatar_src) return;

      return this.info.avatar_src;
    },
  },
  mounted() {
    console.log('INFO TENANT', this.info);
  },
});
