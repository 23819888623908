
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import TenantHeaderDetails from '@/views/new-design/pages/Tenants/TenantHeaderDetails.vue';
import { Actions, Getters, Mutations } from '@/store/enums/TenantEnums';
import { mapActions, mapGetters } from 'vuex';
import timeUtil from '@/utils/time';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  components: { Card, DataTable, TenantHeaderDetails, Breadcrumb },
  data: () => {
    return {
      table: {
        currentPage: 1,
        totalPages: 10,
        perPage: 15,
        total: 0,
        items: [],
        ordering: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      tenantDetails: Getters.GET_TENANT,
      references: Getters.GET_TENANT_REFERENCES,
    }),
    breadcrumbs() {
      return [
        {
          to: '/tenants',
          text: 'Tenant List',
          current: false,
        },
        {
          to: `/tenants/${this.$route.params.tenant_id}`,
          text: 'Tenant Details',
          current: true,
        },
      ];
    },
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        {
          name: 'Submitted Date',
          key: 'date',
          sortable: true,
          hidden: this.isMobile,
        },
        { name: 'Agency', key: 'agency', sortable: true },
        {
          name: 'Submitted By',
          key: 'agent_name',
          sortable: true,
          hidden: this.isMobile,
        },
        { name: 'Tenant Rating', key: 'rating', sortable: true },
      ];
    },
  },
  methods: {
    ...mapActions({
      getTenantDetails: Actions.FIND_EXISTING_TENANT_BY_ID,
      getTenantReferences: Actions.FETCH_TENANT_REFERENCES,
    }),
    async findTenant() {
      await this.getTenantDetails(this.$route.params.tenant_id);
    },
    formatTenantReference() {
      return this.references.map((item) => {
        item.date = item.date_created;
        item.agency = item.user.business_name;
        item.agency_name = `${item.user.first_name} ${item.user.last_name}`;
        item.rating = parseFloat(item.average_rating);

        return item;
      });
    },
    async fetchTenantReferences() {
      await this.getTenantReferences({
        id: this.$route.params.tenant_id,
        returnAll: true,
      }).then(() => {
        this.table.items = this.formatTenantReference();
        this.table.total = this.references.length;
      });
    },
    onPageChanged() {
      console.log('test');
    },
    viewReference(ref) {
      this.$router.push({
        name: 'view-tenant-reference',
        params: { tenant_ref_id: ref.reference_no },
      });
    },
  },
  async mounted() {
    await this.findTenant();
    await this.fetchTenantReferences();
  },
});
